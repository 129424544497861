import * as React from 'react';
import InfoWithImage from '../InfoWithImage/InfoWithImage';
import Warning from '../../assets/warning-illustration.svg';
import Layout from '../layout/Layout';
import { Column, Row } from '../layout/flex-containers';

const BrowserValidator = ({ children }: { children?: React.ReactNode }) =>
  isBrowserUnsupported ? <BrowserNotSupported /> : children;

const BrowserNotSupported = () => (
  <Layout title="Browser non supportato">
    <InfoWithImage
      title="Aggiorna il browser"
      message={unsupportedBrowserDescription}
      image={{ src: Warning, alt: 'browser non supportato' }}
    />
    <Row $center>
      {browsers.map(({ name, img, url }) => (
        <a key={name} href={url} target="_blank" rel="noreferrer">
          <Column className="m-2" $center>
            <img src={img} width="40" height="40" alt={`${name} logo`} />
            <div>{name}</div>
          </Column>
        </a>
      ))}
    </Row>
  </Layout>
);

const features = [
  'indexedDB' in window,
  'noModule' in HTMLScriptElement.prototype,
  'CSS' in window && window.CSS.supports('color', 'var(--primary)'),
  typeof Promise.any === 'function', // ES2020+
];

const isBrowserUnsupported = features.some(condition => !condition);
const unsupportedBrowserDescription =
  "Il tuo browser non supporta le funzionalità  richieste per il corretto funzionamento dell'app. Aggiorna il tuo browser o scarica un browser più moderno.";

const browsers = [
  {
    name: 'Chrome',
    img: 'https://www.google.com/chrome/static/images/chrome-logo.svg',
    url: 'https://www.google.com/chrome/',
  },
  {
    name: 'Firefox',
    url: 'https://www.mozilla.org/it/firefox/new/',
    img: 'https://www.mozilla.org/media/protocol/img/logos/firefox/browser/logo.eb1324e44442.svg',
  },
  {
    name: 'Safari',
    url: 'https://apple.com/safari',
    img: 'https://km.support.apple.com/resources/sites/APPLE/content/live/IMAGES/0/IM26/en_US/safari-240.png',
  },
  {
    name: 'Edge',
    url: 'https://www.microsoft.com/edge',
    img: 'https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4nqTh',
  },
];

export default BrowserValidator;
